

  /* Parallax base styles
  --------------------------------------------- */

  .parallax {
    height: 400px; /* fallback for older browsers */
    height: 90vh;
    overflow-x: hidden;
    /* overflow-y: auto; */
    -webkit-perspective: 300px;
    perspective: 300px;
    /* overflow-y: hidden; */
  }

  .parallax__group {
    position: relative;
    height: 400px; /* fallback for older browsers */
    height: 90vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .parallax__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .parallax__layer--fore {
    -webkit-transform: translateZ(90px) scale(.7);
    transform: translateZ(90px) scale(.7);
    z-index: 1;
  }

  .parallax__layer--base {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    /* transition: visibility 1s linear 1s, opacity 300ms; */
    z-index: 4;
  }

  .parallax__layer--back {
    -webkit-transform: translateZ(-300px) scale(2);
    transform: translateZ(-300px) scale(2);
    z-index: 3;
  }

  .parallax__layer--deep {
    -webkit-transform: translateZ(-600px) scale(3);
    transform: translateZ(-600px) scale(3);
    z-index: 2;
  }


  /* Debugger styles - used to show the effect
  --------------------------------------------- */

  .debug {
    position: fixed;
    bottom: 0;
    left: .5em;
    z-index: 999;
    background: rgba(0, 0, 0, 0.37);
    color: #fff;
    padding: .5em;
    border-radius: 5px 5px 0 0;
  }
  .debug-on .parallax__group {
    -webkit-transform: translate3d(800px, 0, -800px) rotateY(30deg);
    transform: translate3d(700px, 0, -800px) rotateY(30deg);
 }
  .debug-on .parallax__layer {
    box-shadow: 0 0 0 2px #000;
    opacity: 0.9;
  }
  .parallax__group {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
  }


  /* demo styles
  --------------------------------------------- */

  

  body {
    font: 100% / 1.5 Arial;
  }

  * {
    margin:0;
    padding:0;
  }

  .parallax {
    font-size: 150%;
  }

  .whitebkg {
      background-color:rgba(255, 255, 255, 0.658);
  }
  .blackbkg {
      background-color:#000;
      background-size:fill;
  }
   /* centre the content in the parallax layers */
  .title {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }



  /* style the groups
  --------------------------------------------- */

  #group1 {
    z-index: 5; /* slide over group 2 */
  }
  #group1 .parallax__layer--base {
    background: rgb(0, 0, 0);
  }

  #group2 {
    z-index: 3; /* slide under groups 1 and 3 */
  }
  #group2 .parallax__layer--back {
    background: rgb(0, 0, 0);
  }

  #group3 {
    z-index: 4; /* slide over group 2 and 4 */
  }
  #group3 .parallax__layer--base {
    background:rgb(112, 114, 117);
  }

  #group4 {
    z-index: 2; /* slide under group 3 and 5 */
  }
  #group4 .parallax__layer--deep {
    background: rgb(0, 0, 0);
  }

  #group5 {
    z-index: 3; /* slide over group 4 and 6 */
  }
  #group5 .parallax__layer--base {
    background: rgb(214,229,100);
  }

  #group6 {
    z-index: 2; /* slide under group 5 and 7 */
  }
  #group6 .parallax__layer--back {
    background: rgb(245,235,100);
  }

  #group7 {
    z-index: 3; /* slide over group 7 */
  }
  #group7 .parallax__layer--base {
    background: rgb(255,241,100);
  }
  .parallaxpic {
      width:300%;
      height:auto;
      margin-left:-500px;
  }

  /* misc
  --------------------------------------------- */
  .demo__info {
    position: absolute;
    z-index:100;
    bottom: 1vh;
    top: auto;
    font-size:80%;
    text-align:center;
    width: 100%;
  }